
    function sendMail(name, message) {
        var link = "mailto:info@tale-maker.com"
                + "?subject=" + encodeURIComponent("Message from " + name)
                + "&body=" + encodeURIComponent(message)
        ;
        
        window.location.href = link;
    }

    // @ is an alias to /src
    export default {
        name: 'Contact',
        data() {
          return {
            name: "",
            message: "",
          };
        },
        computed: {
          formValid() {
            const { name, message } = this;
            return (
              name.length > 0 &&
              message.length > 0
            );
          },
        },
        methods: {
          onReset() {
            this.name = "";
            this.message = "";
          },
          submit() {
            if (!this.formValid) {
              return;
            }
            const { name, message } = this;
            sendMail(name, message);
          },
        },
      };
